<template>

    <div id="bindcardchange">
      <rxNavBar title="绑定银行卡" ></rxNavBar>
        <div class="cell-normal-header" >
            <span class="cell-title">姓名</span>
            <span class="content-divide">|</span>
            <span class="input-text" >{{openingBankName}}</span>
        </div>
        <div class="cell-normal" >
            <span class="cell-title">卡号</span>
            <span class="content-divide">|</span>
            <span class="input-text" >{{card_id}}</span>
        </div>
        <div class="cell-normal" >
            <span class="cell-title">开户银行</span>
            <span class="content-divide">|</span>
            <span class="input-text" >{{bankOfDeposit}}</span>
        </div>
      <div class="cell-normal" >
        <span class="cell-title">支行</span>
        <span class="content-divide">|</span>
        <span class="input-text" >{{subBank}}</span>
      </div>
		<div class="cell-normal-foot" >
		    <span class="cell-title">行号</span>
		    <span class="content-divide">|</span>
		    <span class="input-text" >{{salesDepartmentNo}}</span>
		</div>

        <van-button  class="bindBankCardChangeButton_Enable"  @click="bind" >修改绑定银行卡</van-button>

    </div>

</template>

<script>


    import {
        NavBar,
        Button
    } from 'vant';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {bankCardBind, queryPersonDetails} from "../../../getData/getData";
    import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Button.name]: Button,
          rxNavBar
        },
        data(){
           return {
               card_id:'',
               bankOfDeposit: '',
			   salesDepartmentNo: '' ,
             subBank: '',
			   openingBankName:''
           }
        },
      created() {
          this.queryPersonDetails()
      },
      methods: {
          queryPersonDetails(){
            let that = this;
            let queryPersonDetailsData={}
            queryPersonDetailsData.user_id= globaluserId()
            queryPersonDetails(queryPersonDetailsData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                console.log(response.data.data)
                let data = response.data.data
                if(data.isBankCode){
                  that.openingBankName = data.openingBankName || ''
                  that.card_id = data.bankCode ||''
                  that.bankOfDeposit = data.openingBank || ''
                  that.subBank = data.subBank || ''
                  that.salesDepartmentNo = data.salesDepartmentNo || ''
                }
              })
            })

          },
            onClickLeft(){
                this.$router.go(-1);
            },
            bind(){
                this.$router.push('bindBankCard')
            }

        },
        computed:
            {
                buttonEnable () {
                    return (this.name.length>0 && this.card_id.toString().length>0 && this.bankOfDeposit.length>0)
                }
            }
    }
</script>


<style lang="less" scoped>
#bindcardchange{
  @container_width:9rem;
  @cell_height:50px;

  input::-webkit-input-placeholder{/*Webkit browsers*/
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /*  Mozilla Firefox 4-18使用伪类 */
  input:-moz-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /* Mozilla Firefox 19+ 使用伪元素  */
  input::-moz-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  /* IE10使用伪类 */
  input:-ms-input-placeholder {
    color: #D9D9D9;
    font-size:16px;
    font-weight: normal;
  }
  .cell-normal{
    //position: relative;
    background-color: white;
    width:@container_width;
    height: @cell_height;
    margin: 2px auto;
  }
  .cell-normal-header{
    //position: relative;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width:@container_width;
    height: @cell_height;
    margin: 25px auto 0px;
  }
  .cell-normal-foot{
    // position: relative;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width:@container_width;
    height: @cell_height;
    margin: 0px auto 19px;
  }
  .cell-title{
    float: left;
    font-size: 15px;
    font-weight: bold;
    height: @cell_height;
    margin-left: 13px;
    line-height: @cell_height;
  }
  .content-divide{
    float: left;
    font-size: 15px;
    color: #efefef;
    margin:0 10px;
    height: @cell_height;
    line-height: @cell_height;
  }
  .input-text{
    //vertical-align:middle;
    float: left;
    display: inline;
    color: #999999;
    font-family: ATTriumvirateCondensed;
    font-size: 15px;
    border: none;
    line-height: @cell_height;
  }
  .font-bold{
    font-weight: bold;
  }

  .bindBankCardChangeButton_Enable{
    // background-color: rgba(184, 184, 184, 0.2);
    width: 9rem;
    height: 1.22rem;
    border-radius: 8px;
    margin-top: 225px;
    margin-left: 19px;
    font-size: 18px;
    color: white;
    line-height: 1.22rem;
    text-align: center;
    background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
  }
}

</style>
